@tailwind base;
@tailwind components;
@tailwind utilities;


/* html,body,heading */
@layer base{
   h1,h2{
    font-family: 'Libre Bodoni', serif;
   }
   p,span,button{
    font-family: 'Work Sans', sans-serif;
   }
   body{
    @apply bg-[#010201] ;
   }
}



/* custom classes */
@layer components{
    .navlist{
        @apply cursor-pointer text-xl  text-[#e5e5e5] transition-colors duration-[0.4s] hover:text-[#FFD600]
    }
    .navlist2{
        @apply cursor-pointer text-lg  text-[#e5e5e5] transition-colors duration-300 hover:text-[#FFD600] 
    }
    .bggradinet{
       background: transparent;
       background: linear-gradient(180deg, rgba(255,255,255,0.7875525210084033) 0%, rgb(3, 3, 3) 99%);
    }
    .underlinestyle{
        border: 5px;
         border-top-left-radius: 5px;
         border-bottom-left-radius: 5px;
         
    }
     .underlinestyle2{
        border: 5px;
         border-top-right-radius: 5px;
         border-bottom-right-radius: 5px;
         
    }
     .underlinestyle3{
         border: 5px;
         border-top-right-radius: 5px;
         border-bottom-right-radius: 5px;
         border-top-left-radius: 5px;
         border-bottom-left-radius: 5px;
         
    }
  
}